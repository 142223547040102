.tag {
	display: inline-block;
	padding: .25em .4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25rem
}

.tag:empty {
	display: none
}

.btn .tag {
	position: relative;
	top: -1px
}

a.tag:focus,
a.tag:hover {
	color: #fff;
	text-decoration: none;
	cursor: pointer
}

.tag-pill {
	padding-right: .6em;
	padding-left: .6em;
	border-radius: 10rem
}

.tag-default {
	background-color: #818a91;
	font-size:1rem;
	margin-right: 3px;
    margin-bottom: 0.2rem;
}

.tag-default_failed {
	color:#fff!important;
	font-size:.8rem;
	padding-top:.1rem;
	padding-bottom:.1rem;
	white-space:nowrap;
	margin-right:3px;
	margin-bottom:.2rem;
	display:inline-block
}

.tag-default[href]:focus,
.tag-default[href]:hover {
	background-color: #687077
}

.tag-primary {
	background-color: #9999CC
}

.tag-primary[href]:focus,
.tag-primary[href]:hover {
	background-color: #449d44
}

.tag-success {
	background-color: #9999CC
}

.tag-success[href]:focus,
.tag-success[href]:hover {
	background-color: #449d44
}

.tag-info {
	background-color: #5bc0de
}

.tag-info[href]:focus,
.tag-info[href]:hover {
	background-color: #31b0d5
}

.tag-warning {
	background-color: #f0ad4e
}

.tag-warning[href]:focus,
.tag-warning[href]:hover {
	background-color: #ec971f
}

.tag-danger {
	background-color: #b85c5c
}

.tag-danger[href]:focus,
.tag-danger[href]:hover {
	background-color: #9d4444
}

.bg-inverse {
	color:#eceeef;
	background-color:#373a3c
}


.tag-outline {
	border:1px solid #ddd;
	color:#aaa!important;
	background:0 0!important
}
ul.tag-list {
	padding-left:0!important;
	display:inline-block;
	list-style:none!important
}

ul.tag-list li {
	display:inline-block!important
}









