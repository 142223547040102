*,*::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: calibri;
}


swiper-container {
    width: 100%;
    height: auto;

}

swiper-slide {
   width: 100%;
   height: 100%;
}

swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

slide1_unused {
    opacity: 1;
    transform-origin: 50% 50% 0px;
    transform: matrix(1.5, 0, 0, 1.5, 0, 0);
    width: 100%;
    height: auto;
    margin-top: -25px;
    margin-left: 0px;
}

/* .swiper-pagination-bullet-active {
	color: #fff;
	background: #007aff;
}

.swiper-pagination-bullet {
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	font-size: 12px;
	color: #000;
	opacity: 1;
	background: rgba(0, 0, 0, 0.2);
} */

